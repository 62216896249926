<div class="main-header pl-2 pr-2 lg:pl-4 md:pr-4 bg-primary main-top-header">
  <div class="grid mt-0">
    <div
      class="col-12 md:col-4 lg:col-4 xl:col-4 flex justify-content-center md:justify-content-start align-items-center">
      <img (click)="goToMainPage()" src="./assets/img/tlogo4.svg" class="cursor-pointer" alt="logo" width="204"
        height="37">
      <span class="h4 text-gray-400 border-left-1 border-gray-400 pl-2 ml-2"> Learning </span>
      <!-- <p-button icon="pi pi-bars" class="ml-5 text-white" (onClick)="openSideBar()" [text]="true" severity="secondary" /> -->
    </div>
    <div class="col-12 md:col-6 lg:col-6 xl:col-7">
      @if (user()) {
      <div class="flex justify-content-center md:justify-content-end align-items-center pt-2 pb-2">
        <p-avatar *ngIf="!user()?.avatar" class="flex mr-2" icon="pi pi-user" shape="circle"></p-avatar>
        <p-avatar *ngIf="user()?.avatar" class="flex mr-2" [image]="user()?.avatar" shape="circle"></p-avatar>
        <div class="md:mr-2 text-white"> {{user()?.firstName}} {{user()?.lastName}}
        </div>
      </div>
      }
    </div>
    <div class="col-12 md:col-2 lg:col-2 xl:col-1 flex justify-content-end md:justify-content-end align-items-center">
      @if (user()) {
      <div>
        <button pButton (click)="logout()"
          class="p-button-sm text-sm text-white p-button-outlined p-button-secondary p-button-outlined">Logout
        </button>
      </div>
      }
    </div>
  </div>
</div>
<app-sidebar-menu [sidebarVisible]="sidebarVisible"></app-sidebar-menu>
