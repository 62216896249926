import { HttpClient } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { AsynchronousActivityRequest, AttendanceModel, AsyncActivitiesUser, AttendanceRequest, AttendanceScheduleRequest } from '@tsin-core/models/attendance.model';
import { environment } from '@tsin-env/environment';
import { Observable } from 'rxjs';

const ATTENDANCE_URL = `${environment.learningBaseUrl}/attendance`;
const ASYNC_ACTIVITY_URL = `${environment.learningBaseUrl}/async-activities`

@Injectable({
  providedIn: 'root',
})
export class AttendanceService {
  http: HttpClient = inject(HttpClient);

  updateAttendanceStatus(payload: AttendanceRequest) {
    return this.http.post(ATTENDANCE_URL, payload);
  }

  updateAsynchronousActivity(payload: AsynchronousActivityRequest): Observable<AttendanceModel> {
    return this.http.post<AttendanceModel>(`${ASYNC_ACTIVITY_URL}`, payload);
  }

  getAttendanceBySchedule(payload: AttendanceScheduleRequest): Observable<AttendanceModel[]> {
    return this.http.get<AttendanceModel[]>(`${ATTENDANCE_URL}/schedule/${payload.weeklyScheduleId}/${payload.sessionId}`);
  }

  getAttendanceByWeeklySchedule(weeklyScheduleId: string): Observable<AttendanceModel[]> {
    return this.http.get<AttendanceModel[]>(`${ATTENDANCE_URL}/schedule/${weeklyScheduleId}`);
  }

  getAsynchronousActivityByWeekly(weeklyScheduleId: string): Observable<AsyncActivitiesUser[]> {
    return this.http.get<AsyncActivitiesUser[]>(`${ASYNC_ACTIVITY_URL}/eligible-users/weeklyId/${weeklyScheduleId}`);
  }

}
