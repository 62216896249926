import { LearningType } from "@tsin-core/entities";
import { LoadingUserState, SchoolAdminRequest } from "@tsin-core/models/user.model";

export class GetLearningGuests {
  static readonly type = '[User] Get Guests By Learning Type';
  constructor(public type: LearningType) { }
}

export class GetLearningUsers {
  static readonly type = '[User] Get Learners By Program ID';
  constructor(public payload: string) { }
}

export class GetLearningAllUsers {
  static readonly type = '[User] Get All Learners';
  constructor(public payload: { type: LearningType, take: number, skip: number }) { }
}

export class GetSchoolAdmins {
  static readonly type = '[User] Get School Admins';
}

export class AddSchoolAdmin {
  static readonly type = '[User] Add';
  constructor(public payload: Omit<SchoolAdminRequest, 'id'>) { }
}
export class UpdateSchoolAdmin {
  static readonly type = '[User] Update';
  constructor(public payload: SchoolAdminRequest) { }
}

export class UpdateSchoolAdminEmail {
  static readonly type = '[User] Update Email';
  constructor(public payload: { id: string; userId: string; email: string; }) { }
}

export class DeleteSchoolAdmin {
  static readonly type = '[User] Delete';
  constructor(public id: string) { }
}

export class SetUserLoading {
  static readonly type = '[User] Set Loading';
  constructor(public loading: LoadingUserState) { }
}

export class SetUserError {
  static readonly type = '[User] Set Error';
  constructor(public error: string | null) { }
}
