import { Routes } from '@angular/router';
import { adminGuard } from '@tsin-core/guards/admin.guard';
import { authGuard } from '@tsin-core/guards/auth.guard';
import { facilitatorGuard } from '@tsin-core/guards/facilitator.guard';
import { universityGuard } from '@tsin-core/guards/university.guard';

export const routes: Routes = [
  { path: '', redirectTo: '/account/validate-token', pathMatch: 'full' },
  { path: 'user', canActivate: [authGuard], loadChildren: () => import('./pages/user/user.routes') },
  { path: 'facilitator', canActivate: [authGuard, facilitatorGuard], data: { preload: true }, loadChildren: () => import('./pages/facilitator/facilitator.routes') },
  { path: 'university', canActivate: [authGuard, universityGuard], data: { preload: true }, loadChildren: () => import('./pages/university/university.routes') },
  { path: 'management', canActivate: [authGuard, adminGuard], data: { preload: true }, loadChildren: () => import('./pages/management/management.routes') },
  { path: 'account', data: { preload: true }, loadChildren: () => import('./pages/account/account.routes') },
  { path: 'sign-up', data: { preload: true }, loadChildren: () => import('./pages/sign-up/sign-up.routes') },
  { path: '**', redirectTo: '/account/validate-token' }
];
