import { Injectable, inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { AuthRequestModel, GuestModel, UserModel } from '@tsin-core/models/auth.model';
import { environment } from '@tsin-env/environment';
import { ChangePasswordRequest } from '@tsin-core/models/profile.model';
import { Observable } from 'rxjs';


const AUTH_VALIDATE_USER = `${environment.userBaseUrl}/auth/authenticate-profiile`;
const AUTH_VALIDATE_TOKEN = `${environment.learningBaseUrl}/user/me`;
const AUTH_ACTIVATE_CODE = `${environment.learningBaseUrl}/guests/activate`;

const AUTH_LOGOUT = `${environment.userBaseUrl}/auth/authenticate-profiile`;
const AUTH_CHANGE_PASSWORD = `${environment.userBaseUrl}/auth/change-password`;

@Injectable({
  providedIn: 'root'
})

// The authentication service
export class AuthService {

  // Inject the http client into auth service
  http: HttpClient = inject(HttpClient);

  // Method for endpoint to validate the user and return a token
  validate(credentials: AuthRequestModel) {
    console.log('CREDENTIALS', credentials)
    return this.http.post(AUTH_VALIDATE_USER, credentials)
  }

  activateGuest(payload: any): Observable<{ guest: GuestModel }> {
    return this.http.post<{ guest: GuestModel }>(AUTH_ACTIVATE_CODE, payload.code);
  }

  validateToken(token: string): Observable<UserModel> {
    return this.http.get<UserModel>(AUTH_VALIDATE_TOKEN, {
      headers: {
        "Authorization": `Bearer ${token}`
      }
    })
  }

  changePassword(credentials: ChangePasswordRequest) {

    console.log('Change Password', credentials);
    return this.http.post(AUTH_CHANGE_PASSWORD, credentials)
  }

  registerUser(formData: any): Observable<any> {
    return this.http.post(`${environment.learningBaseUrl}/user/register-learner`, formData);
  }

  // Method for endpoint to logout a user from the server
  logout() {
    return this.http.delete(AUTH_LOGOUT)
  }
}
