import { assertInInjectionContext, inject } from '@angular/core';
import { CanActivateFn, Router } from '@angular/router';
import { Store } from '@ngxs/store';
import { AuthState } from '@tsin-core/states/auth.state';

const CanActivateFn: CanActivateFn = () => {
  assertInInjectionContext(CanActivateFn);
  const router: Router = inject(Router);
  const store: Store = inject(Store);

  const user = store.selectSnapshot(AuthState.getCurrentUser);
  if (['schoolAdmin'].includes(user?.role!)) {
    return true;
  }
  return router.parseUrl('/account/validate-token');
};

export const universityGuard: CanActivateFn = (route, state) => {
  return CanActivateFn(route, state);
};

