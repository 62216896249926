import { ApplicationModel } from "./application.model";
import { GuestModel, LearnerUserModel, UserModel } from "./auth.model";

export interface UserStateModel {
  users: UserModel[];
  guests: GuestModel[];
  learners: ApplicationModel[];
  schoolAdmins: UserModel[];
  usersCount: number;
  loading: LoadingUserState;
  error: any | null;
}

export interface SchoolAdminRequest {
  id: string;
  userId: string;
  firstName: string;
  lastName: string;
  email: string;
  schoolId: string;
  role: string;
  office: string;
}

// export interface GuestModel {
//     _id: string | null;
//     user: UserModel | null;
//     id: string | null
// }


export enum LoadingUserState {
  loadingList = "loadingList",
  loadingAddUpdate = "loadingAddUpdate",
  loadingDelete = "loadingDelete",
  notLoading = "notLoading",
}
