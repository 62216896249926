import { Component, inject, Signal } from '@angular/core';
import { ButtonModule } from "primeng/button";
import { AvatarModule } from "primeng/avatar";
import { ToolbarModule } from "primeng/toolbar";
import { CardModule } from "primeng/card";
import { CommonModule } from '@angular/common';
import { UserModel } from '@tsin-core/models/auth.model';
import { AuthState } from '@tsin-core/states/auth.state';
import { Observable, take } from 'rxjs';
import { Store } from '@ngxs/store';
import { LogoutAction } from '@tsin-core/actions/auth.action';
import { environment } from '@tsin-env/environment';
import { Router } from '@angular/router';
import { SidebarMenuComponent } from '../sidebar-menu/sidebar-menu.component';
import { determineAuthStatus } from '@tsin-shared/utils/utils';

@Component({
  selector: 'app-main-header',
  standalone: true,
  imports: [CommonModule, AvatarModule, ButtonModule, ToolbarModule, CardModule, SidebarMenuComponent],
  templateUrl: './main-header.component.html',
  styleUrl: './main-header.component.scss'
})
export class MainHeaderComponent {

  store: Store = inject(Store);
  router: Router = inject(Router);
  authState$: Observable<UserModel | null> = this.store.select(AuthState.getCurrentUser).pipe(take(1));
  user: Signal<UserModel | null> = this.store.selectSignal<UserModel | null>(AuthState.getCurrentUser);
  loading$: Observable<boolean> = this.store.select(AuthState.isLoading)
  sidebarVisible: boolean = false;

  goToMainPage() {
    this.authState$.subscribe(user => {
      const url = determineAuthStatus(user);
      this.router.navigate([url]);
    });
  }

  openSideBar() {
    this.sidebarVisible = !this.sidebarVisible;
  }

  logout() {
    // write logout logic
    this.store.dispatch(new LogoutAction());
    this.store.reset({});
    window.location.href = environment.userMgtUrl;
    return;
  }
}
