import { inject, Injectable } from '@angular/core';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import {
  AddFacilitator,
  AssignFacilitator,
  DeleteFacilitator,
  GetFacilitator,
  GetFacilitators,
  GetFacilitatorsInSchedule,
  GetSingleFacilitator,
  SetError,
  SetLoading,
  UnAssignFacilitator,
  UpdateFacilitator,
  UpdateFacilitatorEmail,
  UpdateSingleFacilitator,
} from '@tsin-core/actions/facilitator.action';
import {
  FacilitatorModel,
  FacilitatorStateModel,
  LoadingFacilitatorState,
} from '@tsin-core/models/facilitator.model';
import { UserService } from '@tsin-core/services/http/user.service';
import { catchError, Observable, of, tap } from 'rxjs';

@State<FacilitatorStateModel>({
  name: 'facilitatorState',
  defaults: {
    loading: LoadingFacilitatorState.loadingList,
    facilitators: [],
    selectedFacilitator: null,
    facilitatorSchedules: [],
    error: null,
  },
})
@Injectable()
export class FacilitatorState {
  userService: UserService = inject(UserService);
  //
  @Selector()
  static getFacilitators(state: FacilitatorStateModel) {
    return state.facilitators;
  }

  @Selector()
  static getFacilitatorSchedules(state: FacilitatorStateModel) {
    return state.facilitatorSchedules;
  }

  @Selector()
  static getLoading(state: FacilitatorStateModel) {
    return state.loading;
  }

  @Selector()
  static getError(state: FacilitatorStateModel) {
    return state.error;
  }

  @Selector()
  static getSelectedFacilitator(state: FacilitatorStateModel) {
    return state.selectedFacilitator;
  }


  @Action(GetFacilitatorsInSchedule)
  getFacilitatorsSchedule(
    ctx: StateContext<FacilitatorStateModel>,
    action: GetFacilitatorsInSchedule
  ) {
    ctx.patchState({ loading: LoadingFacilitatorState.loadingAddUpdate });
    return this.userService.getFacilitatorsSchedules(action.weeklyScheduleId).pipe(
      tap((result: any) => {
        console.log(result);
        ctx.patchState({
          facilitatorSchedules: result,
          loading: LoadingFacilitatorState.notLoading,
        });

      }),
      catchError((error) => {
        ctx.patchState({
          loading: LoadingFacilitatorState.notLoading,
          error: error.message,
        });
        // return of(error);
        throw error;
      })
    );
  }


  @Action(AssignFacilitator)
  addAssignFacilitator(
    ctx: StateContext<FacilitatorStateModel>,
    action: AssignFacilitator
  ) {
    ctx.patchState({ loading: LoadingFacilitatorState.loadingList });
    return this.userService.assignFacilitatorUser(action.payload).pipe(
      tap((result: any) => {
        // const state = ctx.getState();
        // let updatedFacilitators = [...state.facilitators];
        // const facilitatorIndex = updatedFacilitators.findIndex(item => item.id === action.payload.facilitatorId);
        // updatedFacilitators[facilitatorIndex].assignments.push(result);


        const state = ctx.getState();
        // Clone the facilitators array
        const updatedFacilitators = state.facilitators.map(facilitator => ({
          ...facilitator,
          assignments: [...facilitator.assignments]  // Clone assignments array
        }));
        // Find the facilitator by ID
        const facilitatorIndex = updatedFacilitators.findIndex(item => item.id === action.payload.facilitatorId);
        if (facilitatorIndex !== -1) {
          updatedFacilitators[facilitatorIndex].assignments.push(result);
        }
        // Set the updated state
        ctx.patchState({
          facilitators: updatedFacilitators, // this does not return the user object created
          loading: LoadingFacilitatorState.notLoading,
        });

      }),
      catchError((error) => {
        ctx.patchState({
          loading: LoadingFacilitatorState.notLoading,
          error: error.message,
        });
        // return of(error);
        throw error;
      })
    );
  }

  @Action(UnAssignFacilitator)
  unAssignFacilitator(
    ctx: StateContext<FacilitatorStateModel>,
    action: UnAssignFacilitator
  ) {
    ctx.patchState({ loading: LoadingFacilitatorState.loadingList });
    return this.userService.unAssignFacilitatorUser(action.payload).pipe(
      tap((result: any[]) => {
        const state = ctx.getState();
        const updatedFacilitators = [...state.facilitators];
        const facilitatorIndex = updatedFacilitators.findIndex(item => item.id === action.payload.facilitatorId);
        // updatedFacilitators[facilitatorIndex] = result;
        updatedFacilitators[facilitatorIndex].assignments = result;
        ctx.patchState({
          facilitators: updatedFacilitators, // this does not return the user object created
          loading: LoadingFacilitatorState.notLoading,
        });
      }),
      catchError((error) => {
        ctx.patchState({
          loading: LoadingFacilitatorState.notLoading,
          error: error.message,
        });
        // return of(error);
        throw error;
      })
    );
  }

  @Action(AddFacilitator)
  addFacilitator(
    ctx: StateContext<FacilitatorStateModel>,
    action: AddFacilitator
  ) {
    ctx.patchState({ loading: LoadingFacilitatorState.loadingAddUpdate });
    return this.userService.createFacilitatorUser(action.payload).pipe(
      tap((result: any) => {
        const state = ctx.getState();
        console.log('Add Facilitator Results', result);
        ctx.patchState({
          facilitators: [...state.facilitators, result], // this does not return the user object created
          loading: LoadingFacilitatorState.notLoading,
        });

      }),
      catchError((error) => {
        ctx.patchState({
          loading: LoadingFacilitatorState.notLoading,
          error: error.message,
        });
        // return of(error);
        throw error;
      })
    );
  }

  @Action(UpdateFacilitator)
  updateFacilitator(
    ctx: StateContext<FacilitatorStateModel>,
    action: UpdateFacilitator
  ) {
    ctx.patchState({ loading: LoadingFacilitatorState.loadingAddUpdate });
    return this.userService.updateFacilitatorUser(action.payload).pipe(
      tap((result: any) => {

        const state = ctx.getState();
        const updatedFacilitators = [...state.facilitators];
        const facilitatorIndex = updatedFacilitators.findIndex((item: FacilitatorModel) => item.id === action.payload.id);
        updatedFacilitators[facilitatorIndex] = result.data;

        ctx.patchState({
          facilitators: updatedFacilitators,
          loading: LoadingFacilitatorState.notLoading,
        });

      }),
      catchError((error) => {
        ctx.patchState({
          loading: LoadingFacilitatorState.notLoading,
          error: error.message,
        });
        // return of(error);
        throw error;
      })
    );
  }


  @Action(UpdateSingleFacilitator)
  updateSingleFacilitator(
    ctx: StateContext<FacilitatorStateModel>,
    action: UpdateSingleFacilitator
  ) {
    ctx.patchState({ loading: LoadingFacilitatorState.loadingAddUpdate });
    return this.userService.updateFacilitatorUser(action.payload).pipe(
      tap((result: any) => {

        ctx.patchState({
          selectedFacilitator: result.data,
          loading: LoadingFacilitatorState.notLoading,
        });

      }),
      catchError((error) => {
        ctx.patchState({
          loading: LoadingFacilitatorState.notLoading,
          error: error.message,
        });
        throw error;
      })
    );
  }

  @Action(UpdateFacilitatorEmail)
  updateFacilitatorEmail(
    ctx: StateContext<FacilitatorStateModel>,
    action: UpdateFacilitatorEmail
  ) {
    ctx.patchState({ loading: LoadingFacilitatorState.loadingAddUpdate });
    return this.userService.updateFacilitatorEmail(action.payload).pipe(
      tap((result: any) => {

        // const state = ctx.getState();
        // const updatedFacilitators = [...state.facilitators];
        // const facilitatorIndex = updatedFacilitators.findIndex(item => item.id === action.payload.id);
        // updatedFacilitators[facilitatorIndex] = result.data;

        // ctx.patchState({
        //   facilitators: updatedFacilitators,
        //   loading: LoadingFacilitatorState.notLoading,
        // });

        ctx.dispatch([new GetFacilitators()]);

      }),
      catchError((error) => {
        ctx.patchState({
          loading: LoadingFacilitatorState.notLoading,
          error: error.message,
        });
        // return of(error);
        throw error;
      })
    );
  }


  @Action(DeleteFacilitator)
  deleteFacilitator(
    ctx: StateContext<FacilitatorStateModel>,
    action: DeleteFacilitator
  ) {
    ctx.patchState({ loading: LoadingFacilitatorState.loadingDelete });
    return this.userService.deleteFacilitatorUser(action.id).pipe(
      tap(() => {
        const state = ctx.getState();
        const filteredFacilitators = state.facilitators.filter(
          (facilitator) => facilitator.id !== action.id
        );
        ctx.patchState({
          facilitators: filteredFacilitators,
          loading: LoadingFacilitatorState.notLoading,
        });
      }),
      catchError((error) => {
        ctx.patchState({
          loading: LoadingFacilitatorState.notLoading,
          error: error.message,
        });
        // return of(error);
        throw error;
      })
    );
  }

  @Action(GetFacilitator)
  getFacilitators(
    ctx: StateContext<FacilitatorStateModel>,
    action: GetFacilitator
  ) {
    ctx.patchState({ loading: LoadingFacilitatorState.loadingList, error: null });
    return this.userService.getFacilitatorUsers().pipe(
      tap((allFacilitators: any) => {
        ctx.patchState({
          facilitators: allFacilitators,
          loading: LoadingFacilitatorState.notLoading,
          error: null,
        });
        // ctx.patchState({ facilitators: facilitators.data, loading: LoadingFacilitatorState.notLoading });
      }),
      catchError((error) => {
        ctx.patchState({
          loading: LoadingFacilitatorState.notLoading,
          error: error.message,
        });
        // return of(error);
        throw error;
      })
    );
  }

  @Action(GetSingleFacilitator)
  getSingleFacilitator(
    ctx: StateContext<FacilitatorStateModel>,
    action: GetSingleFacilitator
  ) {
    ctx.patchState({ loading: LoadingFacilitatorState.loadingList, error: null });
    return this.userService.getFacilitatorById(action.id).pipe(
      tap((facilitator: FacilitatorModel) => {
        console.log('facilitator', facilitator);
        ctx.patchState({
          selectedFacilitator: facilitator,
          loading: LoadingFacilitatorState.notLoading,
          error: null,
        });
        // ctx.patchState({ facilitators: facilitators.data, loading: LoadingFacilitatorState.notLoading });
      }),
      catchError((error) => {
        ctx.patchState({
          loading: LoadingFacilitatorState.notLoading,
          error: error.message,
        });
        // return of(error);
        throw error;
      })
    );
  }

  // @Action(GetTask)
  // getTask(ctx: StateContext<FacilitatorStateModel>, action: GetTask) {
  //     ctx.patchState({ loading: true });
  //     return this.facilitatorservice.getTask(action.id).pipe(
  //         tap((task: Task) => {
  //             ctx.patchState({ loading: false });
  //             const state = ctx.getState();
  //             const tasks = [...state.facilitators];
  //             const taskIndex = tasks.findIndex(item => item.id === task.id);
  //             if (taskIndex === -1) {
  //                 tasks.push(task);
  //             } else {
  //                 tasks[taskIndex] = task;
  //             }
  //             ctx.patchState({ tasks });
  //         }),
  //         catchError((error) => {
  //             ctx.patchState({ loading: false, error: error.message });
  //             return of(error);
  //         })
  //     );
  // }

  @Action(SetLoading)
  setLoading(ctx: StateContext<FacilitatorStateModel>, action: SetLoading) {
    ctx.patchState({ loading: action.loading });
  }

  @Action(SetError)
  setError(ctx: StateContext<FacilitatorStateModel>, action: SetError) {
    ctx.patchState({ error: action.error });
  }
}
