import { state } from "@angular/animations";
import { inject, Injectable } from "@angular/core";
import { Action, Selector, State, StateContext } from "@ngxs/store";
import {
  GetResults,
  ReleaseAllResults,
  ReleaseSingleResult,
  SelectResult,
   SetResultsLoading,
   SetResultsError,
   GetResultsBySchool,
   GetResultsByUser,
    GetSingleResult
  } from "@tsin-core/actions/results.action";
import { LoadingResultsState, ResultModel, ResultsStateModel } from "@tsin-core/models/results.model";
import { ResultsService } from "@tsin-core/services/http/results.service";
import { catchError, of, tap } from "rxjs";


@State<ResultsStateModel>({
  name: 'resultsState',
  defaults: {
    loading: LoadingResultsState.notLoading,
    selectedResult: null,
    results: [],
    error: null,
  }
})

@Injectable()
export class ResultsState {
  resultsService: ResultsService = inject(ResultsService);

  @Selector()
  static getResults(state: ResultsStateModel) {
    return state.results;
  }

  @Selector()
  static getSelectedResult(state: ResultsStateModel) {
    return state.selectedResult;
  }


  @Selector()
  static getLoading(state: ResultsStateModel) {
    return state.loading;
  }

  @Selector()
  static getError(state: ResultsStateModel) {
    return state.error
  }

  @Action(SelectResult)
  selectEntity(ctx: StateContext<ResultsStateModel>, action: SelectResult) {
    const state = ctx.getState();
    const selected = state.results.find(entity => entity.id === action.id);
    ctx.patchState({
      selectedResult: selected
    });
  }


  @Action(ReleaseSingleResult)
  releaseSingleResult(ctx: StateContext<ResultsStateModel>, action: ReleaseSingleResult) {
    ctx.patchState({ loading: LoadingResultsState.loadingAddUpdate });
    return this.resultsService.releaseSingleResult(action.payload).pipe(
      tap((result: any) => {
        const state = ctx.getState();
        console.log('Release Single', result);
      }),
      catchError((error) => {
        ctx.patchState({
          loading: LoadingResultsState.notLoading,
          error: error.message
        });
        throw error;
      })
    )
  }

  @Action(ReleaseAllResults)
  releaseAllResults(ctx: StateContext<ResultsStateModel>, action: ReleaseAllResults) {
      ctx.patchState({ loading: LoadingResultsState.loadingAddUpdate });
      return this.resultsService.releaseAllResults(action.payload).pipe(
          tap((result: any) => {
              const state = ctx.getState();
              console.log('Release All', result);
              ctx.patchState({
                results: [...state.results, result.data],
                loading: LoadingResultsState.notLoading
              })
          }),
          catchError((error) => {
              ctx.patchState({
                  loading: LoadingResultsState.notLoading,
                  error: error.message
              });
              throw error;
          })
      )
  }

  @Action(GetResults)
  getResults(ctx: StateContext<ResultsStateModel>, action: GetResults) {
    ctx.patchState({ loading: LoadingResultsState.loadingList });
    return this.resultsService.getResults().pipe(
      tap((result: any) => {
        const state = ctx.getState();
        ctx.patchState({
          results: result.data,
          loading: LoadingResultsState.notLoading
        })
      }),
      catchError((error) => {
        ctx.patchState({
          loading: LoadingResultsState.notLoading,
          error: error.message
        });
        throw error;
      })
    )
  }


  @Action(GetResultsBySchool)
  getResultsBySchool(ctx: StateContext<ResultsStateModel>, action: GetResultsBySchool) {
    ctx.patchState({ loading: LoadingResultsState.loadingList });
    return this.resultsService.getResultsBySchool(action.payload).pipe(
      tap((results: ResultModel[]) => {
        ctx.patchState({
          results: results,
          loading: LoadingResultsState.notLoading
        })
      }),
      catchError((error) => {
        ctx.patchState({
          loading: LoadingResultsState.notLoading,
          error: error.message
        });
        throw error;
      })
    )
  }

  @Action(GetResultsByUser)
  getResultsByUser(ctx: StateContext<ResultsStateModel>, action: GetResultsByUser) {
    ctx.patchState({ loading: LoadingResultsState.loadingList });
    return this.resultsService.getResultsByUser(action.payload).pipe(
      tap((results: ResultModel[]) => {
        ctx.patchState({
          results: results,
          loading: LoadingResultsState.notLoading
        })
      }),
      catchError((error) => {
        ctx.patchState({
          loading: LoadingResultsState.notLoading,
          error: error.message
        });
        throw error;
      })
    )
  }

  @Action(GetSingleResult)
  getSingleResult(ctx: StateContext<ResultsStateModel>, action: GetSingleResult) {
    ctx.patchState({ loading: LoadingResultsState.loadingList });
    return this.resultsService.getSingleResult(action.payload).pipe(
      tap((result: ResultModel) => {
        ctx.patchState({
          selectedResult: result,
          loading: LoadingResultsState.notLoading
        })
      }),
      catchError((error) => {
        ctx.patchState({
          loading: LoadingResultsState.notLoading,
          error: error.message
        });
        throw error;
      })
    )
  }


  @Action(SetResultsLoading)
  setLoading(ctx: StateContext<ResultsStateModel>, action: SetResultsLoading) {
    ctx.patchState({ loading: action.loading });
  }

  @Action(SetResultsError)
  setError(ctx: StateContext<ResultsStateModel>, action: SetResultsError) {
    ctx.patchState({ error: action.error });
  }

}
