import { HttpInterceptorFn } from '@angular/common/http';
import { inject } from '@angular/core';
import { Store } from '@ngxs/store';
import { AuthState } from '@tsin-core/states/auth.state';

export const jwtInterceptor: HttpInterceptorFn = (req, next) => {
  const store: Store = inject(Store);
  const token = store.selectSnapshot<string | null>(AuthState.getToken);

  if (token && token.length > 0) {
    req = req.clone({
      setHeaders: {
        Authorization: `Bearer ${token}`
      }
    });
  }

  return next(req);
};
