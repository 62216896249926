export interface ScheduleStateModel {
  schedule: ScheduleResponse[];
  weeklySchedules: WeeklyScheduleModel[];
  scheduleExports: any[];
  loading: LoadingScheduleState;
  error: any | null;
}

export interface ScheduleResponse {
  id?: string;
  trackName?: string;
  createdAt?: string;
  updatedAt?: string;
}

export interface WeeklyScheduleModel {
  id?: string;
  name?: string;
  schedules?: SingleScheduleModel[];
}
export interface SingleScheduleModel {
  id: string
  day: Weekdays
  period: "AM" | "PM"
}

export interface ScheduleRequest {
  day: Weekdays
  periods: string[]
  learningId: string
}

export interface WeeklyScheduleRequest {
  id: string,
  name: string,
  learningIds: string[],
  scheduleIds: string[],
}

export interface ScheduleByIdSessionRequest {
  id: string,
  sessionId: string
}

export enum LoadingScheduleState {
  loadingList = 'loadingList',
  loadingAddUpdate = 'loadingAddUpdate',
  loadingDelete = 'loadingDelete',
  notLoading = 'notLoading',
}

type Weekdays = 'Monday' | 'Tuesday' | 'Wednesday' | 'Thursday' | 'Friday';
