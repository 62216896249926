import { Injectable, inject } from "@angular/core";
import { Action, Selector, State, StateContext } from "@ngxs/store";
import { AuthStateModel, GuestModel, OTP_MEANS, UserModel } from "@tsin-core/models/auth.model";
import { AuthenticateAction, LogoutAction, ValidateTokenAction, SetError, ActivateGuestAction } from "@tsin-core/actions/auth.action";
import { AuthService } from "@tsin-core/services/http/auth.service";
import { catchError, tap } from "rxjs/operators";
import { of } from "rxjs";
import { UpdatePassword } from "@tsin-core/actions/profile.action";

const noAuth = {
  token: null,
  id: null,
  email: null,
  role: null,
  user: null,
};

@State<AuthStateModel>({
  name: 'authState',
  defaults: {
    loading: false,
    auth: noAuth,
    guest: null,
    error: null,
  }
})

@Injectable()
export class AuthState {

  authService: AuthService = inject(AuthService);

  @Selector()
  static getToken(state: AuthStateModel): string | null {
    return state.auth.token;
  }

  @Selector()
  static getCurrentUser(state: AuthStateModel): UserModel | null {
    return state.auth.user;
  }

  @Selector()
  static getGuest(state: AuthStateModel): GuestModel | null {
    return state.guest;
  }

  @Selector()
  static getAuthId(state: AuthStateModel): string | null {
    return state.auth.id;
  }



  @Selector()
  static getIsAuthenticated(state: AuthStateModel): boolean {
    return !!state.auth.token;
  }

  @Selector()
  static isLoading(state: AuthStateModel) {
    return state.loading;
  }

  @Selector()
  static getError(state: AuthStateModel) {
    return state.error;
  }

  @Action(AuthenticateAction)
  validateLogin(ctx: StateContext<AuthStateModel>, action: AuthenticateAction) {
    ctx.patchState({ loading: true });
    console.log('Validate Login Started');
    return this.authService.validate(action.payload).pipe(
      tap((result: any) => {
        console.log('Validate Login Success', result);
        ctx.patchState({
          auth: result,
          loading: false
        });
      }),
      catchError((error) => {
        console.log('Validate Login Error', error);
        ctx.patchState({ loading: false, error: error.message });
        throw of(error);
      })
    );
  }


  @Action(ValidateTokenAction)
  validateToken(ctx: StateContext<AuthStateModel>, action: ValidateTokenAction) {
    ctx.patchState({ loading: true });
    console.log('Validate Token Started');
    return this.authService.validateToken(action.payload).pipe(
      tap((result: any) => {
        console.log('Validate Token Started', result);
        ctx.patchState({
          auth: {
            id: result.id,
            email: result.email,
            token: action.payload,
            role: result.role,
            user: result,
          },
          loading: false
        });
      }),
      catchError((error) => {
        console.log('Validate Token Error', error);
        ctx.patchState({ loading: false, error: error.message });
        throw error;
      })
    );
  }


  @Action(ActivateGuestAction)
  activateGuest(ctx: StateContext<AuthStateModel>, action: ActivateGuestAction) {
    ctx.patchState({ loading: true });
    return this.authService.activateGuest(action.payload).pipe(
      tap((result: { guest: GuestModel }) => {
        ctx.patchState({
          guest: result.guest,
          loading: false
        });
      }),
      catchError((error) => {
        ctx.patchState({ loading: false, error: error.message });
        throw error;
      })
    );
  }


  @Action(UpdatePassword)
  changePassword(ctx: StateContext<AuthStateModel>, action: UpdatePassword) {
    ctx.patchState({ loading: true });
    console.log('Change Password');
    return this.authService.changePassword(action.payload).pipe(
      tap((result: any) => {
        console.log('Change Password Success', result);
        ctx.patchState({
          loading: false
        });
      }),
      catchError((error) => {
        ctx.patchState({ loading: false, error: error.message });
        throw error;
      })
    );
  }

  @Action(LogoutAction)
  logOut(ctx: StateContext<AuthStateModel>, action: LogoutAction) {
    ctx.patchState({ loading: true });
    console.log('Logout Started');
    ctx.setState({
      loading: true,
      auth: noAuth,
      guest: null,
      error: null,
    });
    localStorage.clear();  // If using local storage
    sessionStorage.clear(); // If using session storage
  }

}
