import { inject, Injectable } from '@angular/core';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import {
  CreateSchedule,
  CreateWeeklySchedule,
  GetScheduleByIdSession,
  GetSchedulesByLearning,
  GetSchedulesExportData,
  GetWeeklySchedules,
  SetError,
  SetLoading,
} from '@tsin-core/actions/schedule.action';
import {
  LoadingScheduleState,
  ScheduleStateModel,
  WeeklyScheduleModel,
} from '@tsin-core/models/schedule.model';
import { ScheduleService } from '@tsin-core/services/http/schedule.service';
import { catchError, of, tap } from 'rxjs';

@State<ScheduleStateModel>({
  name: 'scheduleState',
  defaults: {
    loading: LoadingScheduleState.loadingList,
    schedule: [],
    weeklySchedules: [],
    scheduleExports: [],
    error: null,
  },
})
@Injectable()
export class ScheduleState {
  scheduleService = inject(ScheduleService);

  @Selector()
  static getTrackManagement(state: ScheduleStateModel) {
    return state.schedule;
  }

  @Selector()
  static getWeeklySchedules(state: ScheduleStateModel) {
    return state.weeklySchedules;
  }

  @Selector()
  static getLoading(state: ScheduleStateModel) {
    return state.loading;
  }

  @Selector()
  static getError(state: ScheduleStateModel) {
    return state.error;
  }

  @Action(GetSchedulesByLearning)
  getSchedule(ctx: StateContext<ScheduleStateModel>, action: GetSchedulesByLearning) {
    ctx.patchState({ loading: LoadingScheduleState.loadingList });

    return this.scheduleService.getSchedule().pipe(
      tap((tracks: any) => {
        ctx.patchState({
          schedule: tracks.data,
          loading: LoadingScheduleState.notLoading,
        });
      }),
      catchError((error) => {
        ctx.patchState({
          loading: LoadingScheduleState.notLoading,
          error: error.message,
        });
        return of(error);
      })
    );
  }


  @Action(GetSchedulesExportData)
  getSimulationAllocationExportData(ctx: StateContext<ScheduleStateModel>, action: GetSchedulesExportData) {
    ctx.patchState({ loading: LoadingScheduleState.loadingList });

    return this.scheduleService.getSimulationAllocationExportData(action.payload).pipe(
      tap((allocations: any) => {
        console.log('RESPOSNE:: ', allocations);
        ctx.patchState({
          scheduleExports: allocations,
          loading: LoadingScheduleState.notLoading,
        });
      }),
      catchError((error) => {
        ctx.patchState({
          loading: LoadingScheduleState.notLoading,
          error: error.message,
        });
        return of(error);
      })
    );
  }


  @Action(CreateWeeklySchedule)
  createWeeklySchedule(
    ctx: StateContext<ScheduleStateModel>,
    action: CreateWeeklySchedule
  ) {
    ctx.patchState({ loading: LoadingScheduleState.loadingAddUpdate });

    return this.scheduleService.createWeeklySchedule(action.payload).pipe(
      tap((result: any) => {
        const state = ctx.getState();
        const weeklySchedulesArray = [...state.weeklySchedules, result.data].reverse();
        ctx.patchState({
          weeklySchedules: weeklySchedulesArray,
          loading: LoadingScheduleState.notLoading,
        });
      }),
      catchError((error) => {
        ctx.patchState({
          loading: LoadingScheduleState.notLoading,
          error: error.message,
        });
        throw error;
      })
    );
  }

  @Action(GetWeeklySchedules)
  getWeeklySchedule(ctx: StateContext<ScheduleStateModel>, action: GetWeeklySchedules) {
    ctx.patchState({ loading: LoadingScheduleState.loadingList });
    return this.scheduleService.getWeeklySchedule().pipe(
      tap((schedules: WeeklyScheduleModel[]) => {
        ctx.patchState({
          weeklySchedules: schedules.reverse(),
          loading: LoadingScheduleState.notLoading,
        });
      }),
      catchError((error) => {
        ctx.patchState({
          loading: LoadingScheduleState.notLoading,
          error: error.message,
        });
        throw error;
      })
    );
  }

  @Action(CreateSchedule)
  createSchedule(
    ctx: StateContext<ScheduleStateModel>,
    action: CreateSchedule
  ) {
    ctx.patchState({ loading: LoadingScheduleState.loadingAddUpdate });

    return this.scheduleService.createSchedule(action.payload).pipe(
      tap((result: any) => {
        const state = ctx.getState();
        ctx.patchState({
          schedule: [...state.schedule, result],
          loading: LoadingScheduleState.notLoading,
        });
      }),
      catchError((error) => {
        ctx.patchState({
          loading: LoadingScheduleState.notLoading,
          error: error.error.message,
        });
        throw error;
      })
    );
  }

  @Action(GetScheduleByIdSession)
  getScheduleByIdSession(ctx: StateContext<ScheduleStateModel>, action: GetScheduleByIdSession) {
    ctx.patchState({ loading: LoadingScheduleState.loadingList });

    return this.scheduleService.getScheduleByIdSession( action.payload ).pipe(
      tap((results: any) => {
        ctx.patchState({
          schedule:  results,
          loading: LoadingScheduleState.notLoading
        });
      }),
      catchError((error) => {
        ctx.patchState({
          loading: LoadingScheduleState.notLoading,
          error: error.message,
        });
        throw error;
      })
    );
  }

  @Action(SetLoading)
  setLoading(ctx: StateContext<ScheduleStateModel>, action: SetLoading) {
    ctx.patchState({ loading: action.loading });
  }

  @Action(SetError)
  setError(ctx: StateContext<ScheduleStateModel>, action: SetError) {
    ctx.patchState({ error: action.error });
  }
}
