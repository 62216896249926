
export class SavePersonalForm {
  static readonly type = '[SignUp] Save Personal Form';
  constructor(public payload: any) { }
}

export class SaveContactForm {
  static readonly type = '[SignUp] Save Contact Form';
  constructor(public payload: any) { }
}

export class SaveEducationForm {
  static readonly type = '[SignUp] Save Education Form';
  constructor(public payload: any) { }
}

export class SaveEmergencyForm {
  static readonly type = '[SignUp] Save Emergency Form';
  constructor(public payload: any) { }
}

export class SavePrivacyForm {
  static readonly type = '[SignUp] Save Privacy Form';
  constructor(public payload: any) { }
}

export class SavePassword {
  static readonly type = '[SignUp] Save Password';
  constructor(public payload: string) { }
}

export class SetProfilePic {
  static readonly type = '[SignUp] Set Profile Pic';
  constructor(public payload: any) { }
}

export class SubmitSignUpForm {
  static readonly type = '[SignUp] Submit Form';
}

export class ClearSignUpForm {
  static readonly type = '[SignUp] Clear Form';
}
