import { LoadingResultsState, ResultBySchoolRequest, SingleResultRequest } from "@tsin-core/models/results.model";

export class ReleaseAllResults {
  static readonly type = '[Results] Release All Results';
  constructor(public payload: { weeklyScheduleId: string }) { }
}

export class ReleaseSingleResult {
  static readonly type = '[Results] Release Single Result';
  constructor(public payload: SingleResultRequest) { }
}

export class GetResults {
  static readonly type = '[Results] Get Results';
  // constructor(public payload: LearningSubmitRequest) { }
}

export class GetResultsBySchool {
  static readonly type = '[Results] Get Results by School';
  constructor(public payload: ResultBySchoolRequest) { }
}

export class GetResultsByUser {
  static readonly type = '[Results] Get Results by User';
  constructor(public payload: string) { }
}

export class GetSingleResult {
  static readonly type = '[Results] Get Single Result';
  constructor(public payload: string) { }
}

export class SelectResult {
  static readonly type = '[Results] Select Result';
  constructor(public id: string) { }
}

export class SetResultsLoading {
  static readonly type = '[Results] Set Results Loading';
  constructor(public loading: LoadingResultsState) { }
}

export class SetResultsError {
  static readonly type = '[Results] Set Results Error';
  constructor(public error: string | null) { }
}
