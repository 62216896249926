import { assertInInjectionContext, inject } from '@angular/core';
import { CanActivateFn, Router } from '@angular/router';
import { Store } from '@ngxs/store';
import { AuthState } from '@tsin-core/states/auth.state';
import { environment } from '@tsin-env/environment';



const canActivateChildFn: CanActivateFn = () => {
  assertInInjectionContext(canActivateChildFn);
  const router: Router = inject(Router);
  const store: Store = inject(Store);

  const user = store.selectSnapshot(AuthState.getCurrentUser);
  if (['superAdmin', 'Admin', 'learningAdmin', 'learnerAdmin'].includes(user?.role!)) {
    return true;
  }
  return router.parseUrl('/account/validate-token');
};

export const adminGuard: CanActivateFn = (route, state) => {
  return canActivateChildFn(route, state);
};
