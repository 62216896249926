import { AuthModel, AuthRequestModel } from "@tsin-core/models/auth.model";

const ACTION_SCOPE = '[Auth]';

export class AuthenticateAction {
  static readonly type = `${ACTION_SCOPE} Login`;
  constructor(public payload: AuthRequestModel) { }
}

export class ValidateTokenAction {
  static readonly type = `${ACTION_SCOPE} Validate Token`;
  constructor(public payload: string) { }
}

export class ActivateGuestAction {
  static readonly type = `${ACTION_SCOPE} Activate Guest Code`;
  constructor(public payload: { code: string }) { }
}

export class LogoutAction {
  static readonly type = `${ACTION_SCOPE} Logout`;
}

export class SetLoading {
  static readonly type = `${ACTION_SCOPE} Set Loading`;
  constructor(public loading: boolean) { }
}

export class SetError {
  static readonly type = `${ACTION_SCOPE} Set Error`;
  constructor(public error: string | null) { }
}
