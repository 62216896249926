import { State, Action, StateContext, Selector } from '@ngxs/store';
import { inject, Injectable } from '@angular/core';
import { catchError, tap } from 'rxjs/operators';
import { SignUpStateModel } from '@tsin-core/models/signup.model';
import { AuthService } from '@tsin-core/services/http/auth.service';
import {
  SavePersonalForm,
  SaveContactForm,
  SaveEducationForm,
  SaveEmergencyForm,
  SavePrivacyForm,
  SavePassword,
  SubmitSignUpForm,
  SetProfilePic,
  ClearSignUpForm
} from '@tsin-core/actions/signup.action';

@State<SignUpStateModel>({
  name: 'signUpState',
  defaults: {
    uploadedPhotoUrl: null,
    signUp: {
      personalForm: null,
      contactForm: null,
      educationForm: null,
      emergencyForm: null,
      privacyForm: null,
      password: null,
    },
    loading: false,
  },
})
@Injectable()
export class SignUpState {

  private authService: AuthService = inject(AuthService);

  @Selector()
  static getSignUpData(state: SignUpStateModel): SignUpStateModel {
    return state;
  }

  @Action(SavePersonalForm)
  savePersonalForm(ctx: StateContext<SignUpStateModel>, action: SavePersonalForm) {
    const state = ctx.getState();
    console.log(action.payload);
    ctx.patchState({ signUp: { ...state.signUp, personalForm: action.payload } });
  }

  @Action(SaveContactForm)
  saveContactForm(ctx: StateContext<SignUpStateModel>, action: SaveContactForm) {
    const state = ctx.getState();
    ctx.patchState({ signUp: { ...state.signUp, contactForm: action.payload } });
  }

  @Action(SaveEducationForm)
  saveEducationForm(ctx: StateContext<SignUpStateModel>, action: SaveEducationForm) {
    const state = ctx.getState();
    ctx.patchState({ signUp: { ...state.signUp, educationForm: action.payload } });
  }

  @Action(SaveEmergencyForm)
  saveEmergencyForm(ctx: StateContext<SignUpStateModel>, action: SaveEmergencyForm) {
    const state = ctx.getState();
    ctx.patchState({ signUp: { ...state.signUp, emergencyForm: action.payload } });
  }

  @Action(SavePrivacyForm)
  savePrivacyForm(ctx: StateContext<SignUpStateModel>, action: SavePrivacyForm) {
    const state = ctx.getState();
    ctx.patchState({ signUp: { ...state.signUp, privacyForm: action.payload } });
  }

  @Action(SavePassword)
  savePassword(ctx: StateContext<SignUpStateModel>, action: SavePassword) {
    const state = ctx.getState();
    ctx.patchState({ signUp: { ...state.signUp, password: action.payload } });
  }

  @Action(SetProfilePic)
  setProfilePic(ctx: StateContext<SignUpStateModel>, action: SetProfilePic) {
    ctx.patchState({ uploadedPhotoUrl: action.payload });
  }

  @Action(ClearSignUpForm)
  clearSignUpForm(ctx: StateContext<SignUpStateModel>) {
    ctx.patchState({
      signUp: {
        personalForm: null,
        contactForm: null,
        educationForm: null,
        emergencyForm: null,
        privacyForm: null,
        password: null,
      },
      loading: false,
    });
  }

  @Action(SubmitSignUpForm)
  submitSignUpForm(ctx: StateContext<SignUpStateModel>) {
    const state = ctx.getState().signUp;
    const avatar = ctx.getState().uploadedPhotoUrl;


    // Flatten the nested object into a single-level object
    const flattenedObject = Object.entries(state).reduce((acc, [key, value]) => {
      console.log(key, '===========');
      if (typeof value === 'object' && value !== null) {
        Object.entries(value).forEach(([subKey, subValue]) => {
          console.log(subKey, subValue);
          if (subKey !== 'avatar') {
            acc[subKey] = subValue; // Add each nested property to the main object
          } else {
            acc['avatar'] = avatar;
          }
        });
      } else {
        acc[key] = value; // If not an object, directly add it
      }
      return acc;
    }, {} as Record<string, any>);

    console.log('FORM DATA', flattenedObject);
    ctx.patchState({ loading: true });
    return this.authService.registerUser(flattenedObject).pipe(
      tap((response: any) => {
        console.log('returns', response);
        ctx.patchState({
          signUp: {
            personalForm: null,
            contactForm: null,
            educationForm: null,
            emergencyForm: null,
            privacyForm: null,
            password: null,
          },
          loading: false,
          uploadedPhotoUrl: null,
        });
      }),
      catchError((error) => {
        ctx.patchState({
          loading: false,
        });
        throw error;
      })
    )

  }
}
