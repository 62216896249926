import { ApplicationStatus, LearnerStatus, LearningType } from "@tsin-core/entities";
import { Maybe } from "@tsin-shared/utils/types";
import { LearningFeeModel, LearningModel } from "./learning.model";
import { UserModel } from "./auth.model";
import { OrientationModel } from "./orientation.model";
import { SchoolsModel } from "./schools.model";


export interface ApplicationStateModel {
  applications: ApplicationModel[];
  selectApplication: ApplicationModel | undefined;
  isUserHasRegistrations: any;
  loading: LoadingApplicationState;
  error: any | null;
}

export interface ApplicationModel {
  _id: string;
  id: string;
  applicationNo: string;
  learning: LearningModel;
  foundationData: LearningModel;
  school: SchoolsModel;
  fee: LearningFeeModel;
  user: UserModel;
  orientation: OrientationModel;
  registrationFee: string;
  administrativeFee: string;
  learningId: string;
  schoolId: string;
  userId: string;
  feeId: string;
  foundationId: string;
  advancedId: string;
  status: ApplicationStatus;
  photo?: string;
  adminAssigned?: string;
  paid: boolean;
  associatedMedicalSchool: string;
  postgraduateTrainingProgram: string;
  nacExamScore: number;
  PGYLevel: string;
  PEAPStartDate: string;
  PEAPEndDate: string;
  familyMedicine: boolean;
  orientationId: string;
  type: LearningType;
  comSimId: Maybe<string>;
  imgBursaryAcknowledgement: Maybe<boolean>;
  imgBursarySocialInsuranceNumber: Maybe<string>;
  imgBursaryInstitutionNumber: Maybe<string>;
  imgBursaryTransitNumber: Maybe<string>;
  imgBursaryAccountNumber: Maybe<string>;
  imgBursaryVoidCheque: string | ArrayBuffer | null;
  howToReceiveT4A?: string;
  t4AEmailAddress?: string;
  streetAddress?: string;
  unitNumber?: number;
  city?: string;
  postalCode?: string;
  country?: string;
  province?: string;
}


export interface ApplicationSubmitRequest {
  // photo: File;
  comSimId: Maybe<string>;
  imgBursaryAcknowledgement: Maybe<boolean>;
  imgBursarySocialInsuranceNumber: Maybe<string>;
  imgBursaryInstitutionNumber: Maybe<string>;
  imgBursaryTransitNumber: Maybe<string>;
  imgBursaryAccountNumber: Maybe<string>;
  imgBursaryVoidCheque: string | ArrayBuffer | null;
  schoolId: Maybe<string>;
  postgraduateTrainingProgram?: Maybe<string>;
  nacExamScore: Maybe<number>;
  PGYLevel: Maybe<string>;
  PEAPStartDate: Maybe<Date>;
  PEAPEndDate: Maybe<Date>;
  familyMedicine: boolean;
  orientationId: Maybe<string>;
  feeId: Maybe<string>;
  foundationId: Maybe<string>;
  advancedId: Maybe<string>;
  learningId: Maybe<string>;
  howToReceiveT4A: Maybe<string>;
  t4AEmailAddress: Maybe<string>;
  streetAddress: Maybe<string>;
  unitNumber: Maybe<string>;
  city: Maybe<string>;
  province: Maybe<string>;
  postalCode: Maybe<string>;
  country: Maybe<string>;
  type: LearningType;
}

export interface PrpFmApplicationSubmitRequest {
  // photo: File;
  comSimId: Maybe<string>;
  learningId: Maybe<string>;
  imgBursaryAcknowledgement: Maybe<boolean>;
  imgBursarySocialInsuranceNumber: Maybe<string>;
  imgBursaryInstitutionNumber: Maybe<string>;
  imgBursaryTransitNumber: Maybe<string>;
  imgBursaryAccountNumber: Maybe<string>;
  imgBursaryVoidCheque: Maybe<File>;
  associatedMedicalSchool: string;
  postgraduateTrainingProgram: string;
  PGYLevel: string;
  PEAPStartDate: string;
  PEAPEndDate: string;
  orientationId: string;
  familyMedicine: boolean;
  type: LearningType;
}


export interface ApplicationStatusUpdateRequest { status: LearnerStatus | null; applicationIds: string[] | null; }

export interface ReassignLearnersRequest {
  foundationId: string,
  learningId: string,
  applicationIds: string[]
}

export interface LearningFeeSubmitRequest {
  registrationFee: string;
  administrativeFee: string;
  type: string;
  id?: string;
}

export interface FeeTypeListModel {
  id: string;
  type: LearningType;
  learningType: string;
  registrationFee: number;
  administrativeFee: number;
}

export enum LoadingApplicationState {
  loadingList = "loadApplicationList",
  loadingAddUpdate = "loadApplicationForm",
  loadingDelete = "loadApplicationDelete",
  notLoading = "notLoadingApplication",
}
