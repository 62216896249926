import { LearningType } from "@tsin-core/entities";

export type AttendanceStatus = "Present" | "Absent" | "AWOL"

export interface AttendanceStateModel {
  attendances: AttendanceModel[];
  asycUsers: AsyncActivitiesUser[];
  loading: LoadingAttendanceState;
  error: any | null;
}

export interface AttendanceRequest {
  id: string;
  attendance: AttendanceStatus;
  comment?: string;
}

export interface AttendanceScheduleRequest {
  weeklyScheduleId: string;
  sessionId: string;
}

export interface AsynchronousActivityRequest {
  userId: string,
  weeklyScheduleId: string,
  asynchronousActivity: {
    sensitiveClinicalExam: "Complete" | "Not Completed",
    obstetricsInFM: "Complete" | "Not Completed",
    onCallSkillsInFM: "Complete" | "Not Completed",
    palliativeEndOfLifeCare: "Complete" | "Not Completed",
    periodicHealthExam: "Complete" | "Not Completed"
  }
}

export interface AttendanceModel {
  id: string,
  sessionId: string,
  weeklyScheduleId: string,
  learningId: string,
  userId: string,
  userName: string,
  PGYLevel: string | null,
  track: string,
  group: string,
  applicationId: string,
  associatedMedicalSchool: string,
  postgraduateTrainingProgram: string,
  attendance: AttendanceStatus,
  asynchronousActivities: any[],
  comment: string,
  totalPresent: number,
  totalSession: number
}

export interface AsyncActivitiesUser {
  id: string;
  firstName: string;
  lastName: string;
  PGYLevel: string;
  applicationNo: string;
  postgraduateTrainingProgram: string;
  asynchronousActivities: any[];
  type: LearningType | null;
}

export interface Activity {
  id: string;
  sensitiveClinicalExam: string;
  obstetricsInFM: string;
  onCallSkillsInFM: string;
  palliativeEndOfLifeCare: string;
  periodicHealthExam: string;
  userId: string;
  weeklyScheduleId: string;
  // Add any other keys if needed
}

export interface ActivitiesData {
  asynchronousActivities: Activity[];
}

export interface StatusCounts {
  complete: number;
  notCompleted: number;
}

export enum LoadingAttendanceState {
  loadingList = 'loadingList',
  loadingAddUpdate = 'loadingAddUpdate',
  loadingDelete = 'loadingDelete',
  notLoading = 'notLoading',
}
