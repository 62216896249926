import { inject, Injectable } from '@angular/core';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import { AddSchoolAdmin, DeleteSchoolAdmin, GetLearningAllUsers, GetLearningGuests, GetLearningUsers, GetSchoolAdmins, SetUserError, SetUserLoading, UpdateSchoolAdmin, UpdateSchoolAdminEmail } from '@tsin-core/actions/user.action';
import { LearnerUserModel, UserModel } from '@tsin-core/models/auth.model';
import { LoadingUserState, UserStateModel } from '@tsin-core/models/user.model';
import { LearnerService } from '@tsin-core/services/http/learner.service';
import { UserService } from '@tsin-core/services/http/user.service';
import { catchError, Observable, of, tap } from 'rxjs';

@State<UserStateModel>({
  name: 'userState',
  defaults: {
    loading: LoadingUserState.loadingList,
    users: [],
    guests: [],
    learners: [],
    schoolAdmins: [],
    usersCount: 0,
    error: null,
  },
})
@Injectable()
export class UserState {
  userService: UserService = inject(UserService);
  learnerService: LearnerService = inject(LearnerService);
  //
  @Selector()
  static getGuests(state: UserStateModel) {
    return state.guests;
  }

  @Selector()
  static getUsers(state: UserStateModel) {
    return state.users;
  }
  @Selector()
  static getUsersCount(state: UserStateModel) {
    return state.usersCount;
  }

  @Selector()
  static getLearners(state: UserStateModel) {
    return state.learners;
  }

  @Selector()
  static getSchoolAdmin(state: UserStateModel) {
    return state.schoolAdmins;
  }

  @Selector()
  static getLoading(state: UserStateModel) {
    return state.loading;
  }

  @Selector()
  static getError(state: UserStateModel) {
    return state.error;
  }

  @Action(GetLearningGuests)
  getLearningGuests(
    ctx: StateContext<UserStateModel>,
    action: GetLearningGuests
  ) {
    ctx.patchState({ loading: LoadingUserState.loadingList });
    return this.userService.getLearningGuests(action.type).pipe(
      tap((guests: any) => {
        console.log('returns', guests);
        ctx.patchState({
          guests,
          loading: LoadingUserState.notLoading,
          error: null,
        });
      }),
      catchError((error) => {
        ctx.patchState({
          loading: LoadingUserState.notLoading,
          error: error.message,
        });
        throw error;
      })
    );
  }


  @Action(GetSchoolAdmins)
  getLearningSchoolAdmins(
    ctx: StateContext<UserStateModel>,
    action: GetSchoolAdmins
  ) {
    ctx.patchState({ loading: LoadingUserState.loadingList });
    return this.userService.getSchoolAdmins().pipe(
      tap((schoolAdmins: any) => {
        console.log('returns', schoolAdmins);
        ctx.patchState({
          schoolAdmins,
          loading: LoadingUserState.notLoading,
          error: null,
        });
      }),
      catchError((error) => {
        ctx.patchState({
          loading: LoadingUserState.notLoading,
          error: error.message,
        });
        throw error;
      })
    );
  }


  @Action(AddSchoolAdmin)
  addSchoolAdmin(
    ctx: StateContext<UserStateModel>,
    action: AddSchoolAdmin
  ) {
    ctx.patchState({ loading: LoadingUserState.loadingAddUpdate });
    return this.userService.createSchoolAdminUser(action.payload).pipe(
      tap((result: any) => {
        ctx.dispatch([new GetSchoolAdmins()]);
      }),
      catchError((error) => {
        ctx.patchState({
          loading: LoadingUserState.notLoading,
          error: error.message,
        });
        throw error;
      })
    );
  }

  @Action(UpdateSchoolAdmin)
  updateSchoolAdmin(
    ctx: StateContext<UserStateModel>,
    action: UpdateSchoolAdmin
  ) {
    ctx.patchState({ loading: LoadingUserState.loadingAddUpdate });
    return this.userService.updateSchoolAdminUser(action.payload).pipe(
      tap((result: any) => {

        ctx.dispatch([new GetSchoolAdmins()]);

      }),
      catchError((error) => {
        ctx.patchState({
          loading: LoadingUserState.notLoading,
          error: error.message,
        });
        // return of(error);
        throw error;
      })
    );
  }


  @Action(UpdateSchoolAdminEmail)
  updateSchoolAdminEmail(
    ctx: StateContext<UserStateModel>,
    action: UpdateSchoolAdminEmail
  ) {
    ctx.patchState({ loading: LoadingUserState.loadingAddUpdate });
    return this.userService.updateSchoolAdminEmail(action.payload).pipe(
      tap((result: any) => {
        console.warn('UPDATE SCHOOL RESULT', result);
        ctx.dispatch([new GetSchoolAdmins()]);
      }),
      catchError((error) => {
        ctx.patchState({
          loading: LoadingUserState.notLoading,
          error: error.message,
        });
        // return of(error);
        throw error;
      })
    );
  }


  @Action(DeleteSchoolAdmin)
  deleteSchoolAdmin(
    ctx: StateContext<UserStateModel>,
    action: DeleteSchoolAdmin
  ) {
    ctx.patchState({ loading: LoadingUserState.loadingDelete });
    return this.userService.deleteSchoolAdminUser(action.id).pipe(
      tap(() => {
        const state = ctx.getState();
        const filteredSchoolAdmins = state.schoolAdmins.filter(
          (schoolAdmins) => schoolAdmins.id !== action.id
        );
        ctx.patchState({
          schoolAdmins: filteredSchoolAdmins,
          loading: LoadingUserState.notLoading,
        });
      }),
      catchError((error) => {
        ctx.patchState({
          loading: LoadingUserState.notLoading,
          error: error.message,
        });
        // return of(error);
        throw error;
      })
    );
  }

  @Action(GetLearningAllUsers)
  getAllLearningUsers(
    ctx: StateContext<UserStateModel>,
    action: GetLearningAllUsers
  ) {
    ctx.patchState({ loading: LoadingUserState.loadingList });
    return this.learnerService.exportAllLearners(action.payload).pipe(
      tap((learners: any) => {
        console.log(learners?.data);
        ctx.patchState({
          learners: learners?.data?.applications,
          usersCount: learners?.data?.count,
          loading: LoadingUserState.notLoading,
          error: null,
        });
      }),
      catchError((error) => {
        ctx.patchState({
          loading: LoadingUserState.notLoading,
          error: error.message,
        });
        return of(error);
      })
    );
  }

  @Action(GetLearningUsers)
  getLearningUsers(
    ctx: StateContext<UserStateModel>,
    action: GetLearningUsers
  ) {
    ctx.patchState({ loading: LoadingUserState.loadingList });
    return this.learnerService.exportLearnersById(action.payload).pipe(
      tap((learners: any) => {
        console.log(learners);
        ctx.patchState({
          learners: learners,
          loading: LoadingUserState.notLoading,
          error: null,
        });
      }),
      catchError((error) => {
        ctx.patchState({
          loading: LoadingUserState.notLoading,
          error: error.message,
        });
        return of(error);
      })
    );
  }

  @Action(SetUserLoading)
  setLoading(ctx: StateContext<UserStateModel>, action: SetUserLoading) {
    ctx.patchState({ loading: action.loading });
  }

  @Action(SetUserError)
  setError(ctx: StateContext<UserStateModel>, action: SetUserError) {
    ctx.patchState({ error: action.error });
  }
}
