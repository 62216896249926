
export interface ResultsStateModel {
    loading: LoadingResultsState,
    selectedResult: ResultModel | null,
    results: ResultModel[],
    error: any | null,
}


export interface ResultModel {
    id: string;
    userId: string;
    firstName: string;
    lastName: string;
    attendanceStatus: string;
    asyncStatus: string;
    name: string | null;
    fileName: string | null;
    fileUrl: string | null;
    schoolId: string | null;
    postgraduateTrainingProgram: string;
    associatedMedicalSchool: string;
    weeklyScheduleId: string;
    data: {
        communicatesClearly: number,
        usesAppropriateNonVerbal: number,
        gathersRelevantInformation: number,
        prioritizesNeeds: number,
        respondsAppropriately: number,
        reflectsOnSimulations: number,
        analyzePatientNeeds: number,
        identifiesResources: number,
        overallMedicalExpert: number,
        comment: string | null
    },
    application: {
        PGYLevel: string,
        learning: {
            endDate: Date,
            learningNo: string,
            startDate: Date,
        },
        applicationNo: string,
        userName: string,
    },
    createdAt: Date
}

export interface ResultBySchoolRequest {
    weeklyScheduleId?: string;
    schoolId: string;
}

export interface SingleResultRequest {
    weeklyScheduleId: string;
    userId: string;
}

export enum LoadingResultsState {
    loadingList = "loadLearningList",
    loadingAddUpdate = "loadLearningForm",
    loadingDelete = "loadLearningDelete",
    notLoading = "notLoadingLearning",
}
