import { FormGroup, AbstractControl, FormControl } from "@angular/forms";
import { UserModel } from "@tsin-core/models/auth.model";
import { environment } from "@tsin-env/environment";
import CryptoJS from "crypto-js";


// Form Utilities for Validation
export function getFormField(form: FormGroup, fieldName: string): AbstractControl {
  if (form) {
    const field: AbstractControl | null = form.get(fieldName);
    return field ? field : new FormControl('');
  }
  return new FormControl('');
}

export function formatDateCustom(dateString: string): string {
  const date = new Date(dateString);

  const day = String(date.getDate()).padStart(2, '0'); // Day with leading zero
  const month = String(date.getMonth() + 1).padStart(2, '0'); // Month with leading zero (0-based index)
  const year = date.getFullYear(); // Full year

  return `${day}-${month}-${year}`;
}


export function getDateFmt(dateStr: Date): string {
  const date = new Date(dateStr);

  // Create a formatted string with custom logic for day suffix (st, nd, rd, th)
  const day = date.getDate();
  const daySuffix = (day: any) => {
    if (day > 3 && day < 21) return 'th'; // for 4th to 20th
    switch (day % 10) {
      case 1: return 'st';
      case 2: return 'nd';
      case 3: return 'rd';
      default: return 'th';
    }
  };

  // Format options for day name and month
  const options = { weekday: 'long', month: 'long', year: 'numeric' };
  return `${date.toLocaleDateString('en-US', { weekday: 'long' })} ${day}${daySuffix(day)} of ${date.toLocaleDateString('en-US', { month: 'long' })}, ${date.getFullYear()}`;
}

export function formatTime(dateInput: any): string {
  try {
    if (typeof dateInput === 'string' && isValidISODate(dateInput as string)) {
      return convertToEST(dateInput);
    } else if (typeof dateInput === 'string') {
      return dateInput;
    } else if (dateInput instanceof Date) {
      const timeZone: string = 'America/New_York' // Assuming EDD corresponds to Eastern Daylight Time
      return formatDateToTimeWithTimeZone(dateInput, timeZone);
    }
    return '';
  } catch (e) {
    console.log(e);
    return '';
  }
}

export function convertToEST(isoDateString: string): string {
  const date = new Date(isoDateString);

  // Convert to EST using Intl.DateTimeFormat
  const options: Intl.DateTimeFormatOptions = {
    hour: '2-digit',
    minute: '2-digit',
    timeZone: 'America/New_York',
    hour12: false, // Use 24-hour format (set to `true` for AM/PM)
  };

  const formatter = new Intl.DateTimeFormat('en-US', options);
  return formatter.format(date) + ' EST';
}

function isValidISODate(dateString: string): boolean {
  // Check if the string matches the ISO 8601 format
  const isoRegex = /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}\.\d{3}Z$/;

  if (!isoRegex.test(dateString)) {
    return false;
  }

  // Attempt to parse as a Date object
  const date = new Date(dateString);

  // Ensure it is a valid date and not "Invalid Date"
  return date instanceof Date && !isNaN(date.getTime());
}


export function formatDateToTimeWithTimeZone(date: Date, timeZone: string): string {
  const options: Intl.DateTimeFormatOptions = {
    hour: 'numeric',
    minute: 'numeric',
    hour12: true,
    timeZoneName: 'short',
    timeZone,
  };

  return new Intl.DateTimeFormat('en-US', options).format(date);
}

export function passwordMatchValidator(form: FormGroup) {
  const password = form.get('password')?.value;
  const confirmPassword = form.get('confirmPassword')?.value;
  // Check if both passwords match
  return password === confirmPassword ? null : { mismatch: true };
}


export function determineAuthStatus(user: UserModel | null): string {
  switch (user?.role) {
    case 'schoolAdmin':
      return `/university`;

    case 'facilitatorAdmin':
      return `/facilitator`;

    case 'superAdmin':
    case 'learnerAdmin':
    case 'admin':
      return `/management`;

    case 'learnerStudent':
    case 'student':
    default:
      return `/user/${user?.examType}`;
  }
}

export function encrypt(password: string) {
  return CryptoJS.AES.encrypt(password, environment.salt).toString();

}
